import SidebarService from 'src/components/ui/SidebarService/SidebarService'

import '../styles.scss'

const RegrasPromocoes = () => {
  return (
    <>
      <section className="section-services promotions-rules_container w-full">
        <div className="av-container">
          <div className="av-row section-services__flex">
            <div className="av-col-xs-24 av-col-md-7 section-services__column">
              {/* <!-SideBar RegrasPromocoes-> */}
              <SidebarService />
            </div>
            <div className="av-col-xs-24 av-col-md-17">
              {/* <!- RegrasPromocoes Products-> */}
              <div className="services-content">
                <div className="section__top align-center">
                  <h1 className="text-blue italic text-2xl text-center font-bold">
                    PROMOÇÕES
                  </h1>
                  <div className="hro">
                    <h2 className="text-gray italic text-lg font-bold text-left">
                      COMPRE 3 PRODUTOS E GANHE 25% OFF
                    </h2>

                    <p className="text-left text-sm text-gray mt-4">
                      Condições de Participação
                      <br />
                      <br />A promoção é válida apenas para a lista de produtos
                      selecionados, vendidos e entregues pela Iguasport. Para
                      obtenção do desconto o cliente deve escolher no mínimo 3
                      itens, todos da lista de produtos selecionados, adicionar
                      no carrinho e então o desconto será aplicado no item de
                      menor valor. Promoção exclusiva para a loja online VÁLIDA
                      POR TEMPO INDETERMINADO, PODENDO SER CANCELADA A QUALQUER
                      MOMENTO.
                    </p>
                  </div>
                  <h2 className="text-gray italic text-lg font-bold text-left">
                    FRETE GRÁTIS BRASIL
                  </h2>
                  <p className="text-left text-sm text-gray mt-4">
                    O frete é grátis apenas nos casos de compras* de produtos
                    das categorias calçados e vestuário vendidos e entregues
                    pela Decathlon, e desde que a opção de entrega selecionada
                    seja a modalidade econômica**.
                  </p>
                  <p className="text-left text-sm text-gray mt-4">
                    *A promoção frete grátis é válida para compras com o
                    seguinte valor mínimo por região:
                  </p>
                  <br />

                  <section className="container-tableFrete">
                    <table className="tableFrete">
                      <thead>
                        <tr>
                          <th>
                            <span id="docs-internal-guid-b6493388-7fff-223b-fe58-ea0d41a6a9c3">
                              <span>Região</span>
                            </span>
                            <br />
                          </th>
                          <th>
                            <span id="docs-internal-guid-2c32c43d-7fff-3fa5-814d-a784a8a0e28b">
                              <span>Compras no site</span>
                            </span>
                            <br />
                          </th>
                          <th>
                            <span id="docs-internal-guid-8b425345-7fff-11f9-c037-a4ccc87ec302">
                              <span>Compras no App</span>
                            </span>
                            <br />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Sul</td>
                          <td>
                            <span id="docs-internal-guid-d4e542b1-7fff-19c9-853d-3e9ab3115ae1">
                              <span>A partir de R$ 269,99</span>
                            </span>
                          </td>
                          <td>
                            <span id="docs-internal-guid-eab46bb2-7fff-3091-4134-7d9c883cfe6a">
                              <span>A partir de R$ 229,99</span>
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>Sudeste</td>
                          <td>
                            <span id="docs-internal-guid-f92be3de-7fff-45f2-b47a-bfa5ccf511ea">
                              <span>A partir de R$ 229,99</span>
                            </span>
                          </td>
                          <td>
                            <span id="docs-internal-guid-06e8b3ff-7fff-59ed-cac9-f052e202a066">
                              <span>A partir de R$ 199,99</span>
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>Centro-Oeste</td>
                          <td>
                            <span id="docs-internal-guid-606056c6-7fff-da84-32e0-8905021fb255">
                              <span>A partir de R$ 299,99</span>
                            </span>
                          </td>
                          <td>
                            <span id="docs-internal-guid-0609bb28-7fff-f19f-29c6-254f114f4385">
                              <span>A partir de R$ 249,99</span>
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>Nordeste</td>
                          <td>
                            <span id="docs-internal-guid-258a7f4c-7fff-07e3-1b34-f286888a964f">
                              <span>A partir de R$ 349,99</span>
                            </span>
                          </td>
                          <td>
                            <span id="docs-internal-guid-0387e998-7fff-1e90-c7ee-70ecf53bf619">
                              <span>A partir de R$ 299,99</span>
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>Norte</td>
                          <td>
                            <span id="docs-internal-guid-ae069b14-7fff-38a7-4ada-307e4e545eee">
                              <span>A partir de R$ 399,99</span>
                            </span>
                          </td>
                          <td>
                            <span id="docs-internal-guid-baf05f20-7fff-51e4-7234-c6249e91fa48">
                              <span>A partir de R$ 349,99</span>
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>

                  <h3 className="text-left text-sm text-gray font-bold ">
                    Importante:
                  </h3>

                  <p className="text-left text-sm text-gray mt-2">
                    Um pedido com mais de um produto no carrinho pode ser
                    quebrado em duas ou mais entregas, dependendo da situação do
                    estoque de cada produto.
                    <br />
                    <br />
                    A regra do valor mínimo de compras para frete grátis é
                    aplicada a cada um dos grupos de entrega separadamente e não
                    para o valor total do pedido.
                    <br />
                    <br />
                    Itens promocionais, acessórios, equipamentos, incluindo, sem
                    limitação, bicicletas (incluindo spinning e ergométricas),
                    aparelhos de ginástica e musculação, elípticos, caneleiras,
                    halteres, caiaques, pranchas, mesas, cadeiras, sacos de
                    pancada, tatames, traves, tabelas, entre outros, estão
                    sujeitos à cobrança de frete.
                    <br />
                    <br />
                    ** Serão consideradas entrega "econômica" os envios
                    efetuados no maior prazo indicado, bem como originários do
                    centro de distribuição da Decathlon.
                    <br />
                    <br />
                    A modalidade de frete "Envio pela loja" é exclusiva para
                    pedidos que são despachados diretamente da loja, e não do
                    nosso centro de distribuição. Portanto, as regras de frete
                    grátis não se aplicam a essa modalidade e será cobrada a
                    taxa de entrega no momento da compra.
                    <br />
                    <br />
                    Serão consideradas entrega padrão os envios efetuados no
                    maior prazo indicado, bem como originários do centro de
                    distribuição da Decathlon.
                  </p>
                </div>
                <div className="hro">
                  <h2 className="text-gray italic text-lg font-bold text-left">
                    CUPOM BEMVINDO
                  </h2>

                  <p className="text-left text-sm text-gray mt-4">
                    Baixe nosso aplicativo e tenha 10% de desconto em sua
                    primeira compra.
                  </p>

                  <br />
                  <h3 className="text-left text-sm text-gray font-bold ">
                    Importante:
                  </h3>

                  <ol className="mt-2 text-left text-sm text-gray pl-7">
                    <li className="text-sm text-gray">
                      01. Promoção válida exclusivamente para a primeira compra
                      no aplicativo Decathlon. Cada CPF pode utilizar o cupom
                      somente uma única vez.
                    </li>
                    <li className="text-sm text-gray">
                      02. Cupom aplicável para compras acima de R$149,99 (cento
                      e quarenta e nove reais e noventa e nove centavos). O
                      cupom de desconto não será aplicado sobre os valores de
                      itens promocionais, bicicletas de quaisquer modelos,
                      estações de musculação, bancos e racks de musculação,
                      aparelhos de ginástica, Bicicleta Spinning, Bike
                      Ergométrica, Elíptico, Esteira, Remo, Step, Trampolim,
                      Tabelas de Basquete, Tatame, Saco de Pancada, Mesa de Ping
                      Pong, Pranchas, Móveis de Acampamento e cartão presente.
                    </li>
                    <li className="text-sm text-gray">
                      03. Desconto concedido de 10% sobre o valor dos produtos -
                      não incluso o frete.
                    </li>
                    <li className="text-sm text-gray">
                      04. Exclusivo para produtos vendidos e entregues por
                      Decathlon (Iguasport).
                    </li>
                    <li className="text-sm text-gray">
                      05. A promoção <b>não é cumulativa</b> com os demais
                      cupons vigentes
                    </li>
                    <li className="text-sm text-gray">
                      06. Para conseguir o desconto é preciso inserir o cupom
                      "bemvindo" no campo correspondente diretamente no carrinho
                      do aplicativo.
                    </li>
                    <li className="text-sm text-gray">
                      07. Caso necessite realizar a troca de um produto que foi
                      adquirido com o desconto do cupom Bemvindo, o valor do
                      vale troca será emitido considerando o que foi pago no(s)
                      produtos(s) e não será aplicado o desconto de 10%.
                    </li>
                    <li className="text-sm text-gray">
                      08. Dúvidas{' '}
                      <a
                        className="text-blue"
                        href="https://www.decathlon.com.br/atendimento"
                        target="_blank"
                        rel="noreferrer"
                      >
                        www.decathlon.com.br/atendimento
                      </a>{' '}
                      ou{' '}
                      <a
                        className="text-blue"
                        href="https://api.whatsapp.com/send/?phone=5511947312606&text=Ol%C3%A1,%20gostaria%20de%20falar%20com%20um%20especialista%20do%20esporte."
                        target="_blank"
                        rel="noreferrer"
                      >
                        chame no whatsapp
                      </a>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default RegrasPromocoes
