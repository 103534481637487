import Seo from 'src/components/seo/Seo'
import RegrasPromocoes from 'src/components/servicos/ServicesPage/RegrasPromocoes'

function Page() {
  return (
    <>
      <Seo title="Regras de Promoções | Decathlon" />
      <RegrasPromocoes />
    </>
  )
}

export default Page
